import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map, tap, iif, defer, of, switchMap, filter } from 'rxjs';
import {
  RecipientCampaignPreviewResponse,
  TSignaturePlaceholder,
  TSignaturePlaceholderValue
} from 'src/app/model/interfaces/signature.interface';

import { AlertService } from '../alert/alert.service';
import { IAccount, IMAILTASTIC_AUTHORIZATION_SCOPE } from 'src/app/services/account/account-service.interface';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import {
  DataOrMessage,
  DataOrSuccess,
  IResponse,
  IResponseData,
  IResponseMessage,
  IResponseMessageCode,
  Response,
  ResponseData,
  ResponseGoogleLogin,
  ResponseMessage,
  ResponseMessageOrCode,
  ResponseO365SSO
} from 'src/app/model/interfaces/response.interface';

import {
  EmployeesWithHiddenFields,
  EmployeesWithHiddenProfilePhoto,
  GoogleExistingDomainBody,
  GoogleUser,
  IUpdateUser,
  IUser,
  IUserGetAdmin,
  IUserGetAdmins,
  IUserInvitationSignUp,
  M365ExistingDomainBody,
  M365LoginParams,
  UserSignUp
} from 'src/app/model/interfaces/user.interface';

import {
  ActivateExternalAdminResponse,
  ChangeEasySyncSettings,
  CustomerEmails,
  GetOverallStatsResponse,
  isLoginFailed,
  IUserCheckInvitationCode,
  IUserLoginFailed,
  IUserLoginSuccess,
  IUserOptOutDetails,
  IUserSetCompanyInfoSingle,
  IUserUpdateNewAdminByInvitation,
  OnboardingAnswerDataV2,
  ResponseEasySync,
  UserChangeOutlookAddinSettings,
  UserGetAccountData,
  UserGetOutlookAddinSettings,
  UserLoggedInfo,
  UserLoggedInfoV2
} from './user-service.interface';
import { Router } from '@angular/router';
import { INotificationLanguage, INotificationSetting } from 'src/app/model/interfaces/notification.interface';
import { RBARole, UserGetAdvancedAdmins } from '@services/rba/rba-settings.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AuthService } from 'src/app/services/auth/auth.service';

import { IntercomService, INTERCOM_DATA } from 'src/app/services/intercom/intercom.module';
import { environment } from 'src/environments/environment';
import { AddPaymentCredentials } from 'src/app/model/interfaces/payment.interface';
import { EBoxType } from '@model/enums/box-type.enum';
import { SourcebusterCookieService } from '@services/cookies/sourcebuster-cookie.service';
import { TranslocoService } from '@ngneat/transloco';
import { AccountService } from '@services/account/account.service';
import { ClientServers, SendMailDataToNonMTAdmin } from '@model/interfaces/integrations.interface';
import { format } from 'date-fns';
import { languageTypes } from '@services/language/language.service.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private operator: CustomOperators,
    private router: Router,
    private sourcebusterCookieService: SourcebusterCookieService,
    public translateService: TranslocoService
  ) {}

  /**
   * Login to the application using a mailtastic account
   * @param email - Account email
   * @param password - Account password
   * @param isSuperAdmin - The super admin trigger
   * @returns The login data
   */
  login(email: string, password: string, isSuperAdmin = false): Observable<IUserLoginSuccess> {
    let loginApi = this.http.post<IUserLoginSuccess>('/account/authenticate/login', {
      email,
      password
    });

    if (isSuperAdmin) {
      loginApi = this.getCustomerLogin(email);
    }

    return loginApi.pipe(
      switchMap(result => {
        return iif(
          () => result.code === 7 || result.code === 3,
          defer(() => this.resendActivationLink(email)),
          defer(() => of(result))
        );
      }),
      map(res => {
        const result = res as IUserLoginSuccess;

        if (isLoginFailed(result)) {
          // TODO messages for each error code
          if (result.code === 7) throw new Error(this.alert.translate('ACTIVATION_NEEDED'));

          if (result.code === 8) throw new Error('TRY_TO_LOGIN_WITH_SSO');

          if (result.code === 9) throw new Error('login_problem_occured');

          if (result.code === 3) throw new Error(this.alert.translate('ACTIVATION_NEEDED'));

          // If account allows only SSO way of authentication block admin from loging in
          if (result.code === 33) throw new Error(this.alert.translate('login_denied_reason_force_sso'));

          // If attempted to login with wrong password 5 or more times
          if (typeof result.attempts === 'boolean' && !result.attempts) {
            throw new Error('wrong_password_5_or_more_times');
          }

          throw new Error(this.alert.translateDataNotLoaded());
        } else {
          this.intercomService.userLoggedIn(result);
        }
        return result;
      }),
      catchError((err: HttpErrorResponse) => {
        throw new Error(err.message);
      })
    );
  }

  /**
   * Gets all customer for super admin
   * @param searchValue The text which is search by super admin for customer email
   * @returns Array of customer emails
   */
  getAllCustomers(searchValue: string): Observable<CustomerEmails[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        superadminauthorization: this.authService.getSuperAdminToken()
      })
    };
    return this.http
      .get<ResponseData<CustomerEmails[]>>(`/superAdmin/getAllCustomers?search=${searchValue}`, httpOptions)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the customer login data a super admin account
   * @param email - Customer account email
   * @returns The login success data
   */
  getCustomerLogin(email: string): Observable<IUserLoginSuccess> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        superadminauthorization: this.authService.getSuperAdminToken()
      })
    };
    return this.http
      .post<ResponseData<IUserLoginSuccess>>(
        '/superAdmin/authenticate/customerlogin',
        {
          email
        },
        httpOptions
      )
      .pipe(map(res => res.data));
  }

  /**
   * Resend activation link of new account
   * @param email - The email of registered with our application
   * @returns Observable containing success or code
   */
  resendActivationLink(email: string): Observable<ResponseMessageOrCode> {
    return this.alert
      .defaultConfirmationPrompt(
        this.alert.translate('confirmationmail_error_text'),
        EBoxType.ERROR,
        this.alert.translate('confirmationmail_error_hl'),
        this.alert.translate('confirmationmail_error_button')
      )
      .pipe(
        switchMap(() => this.resendRegistrationConfirmationEmail(email)),
        tap(() => {
          void this.router.navigate(['/signup'], { state: { email, isResendEmail: true } });
        }),
        filter(() => false)
      );
  }

  /**
   * Login to the application using a google account with Google's SSO
   * @param googleUser - Object containing google account's information
   * @returns Observable containing success of operation and login information
   */
  loginWithGoogle(googleUser: GoogleUser): Observable<ResponseGoogleLogin> {
    return this.http.post<ResponseGoogleLogin>('/account/authenticate/loginwithgoogle', googleUser).pipe(
      this.operator.extractResponseGoogleLogin(),
      tap(result => {
        if (result.login && result.login?.isSuperAdmin) {
          this.accountService.setSuperAdminAccountData(result.login);
          this.authService.updateCustomerLoginEmails([]);
        }
      })
    );
  }

  /**
   * Register to the application using a google account with Google's SSO
   * @param googleUser - Object containing google account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithGoogle(googleUser: GoogleUser): Observable<ResponseGoogleLogin> {
    googleUser.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseGoogleLogin>('/account/authenticate/registerwithgoogle', googleUser)
      .pipe(this.operator.extractResponseGoogleLogin());
  }

  /**
   * Login to the application using a O365 account with O365 s SSO
   * @param o365User - Object containing O365 account's information
   * @returns Observable containing success of operation and login information
   */
  loginWithM365(o365User: M365LoginParams): Observable<ResponseO365SSO> {
    return this.http.post<ResponseO365SSO>('/account/authenticate/loginwithoffice365', o365User).pipe(
      this.operator.extractResponseO365SSO(),
      tap(result => {
        if (result.login && result.login?.isSuperAdmin) {
          this.accountService.setSuperAdminAccountData(result.login);
          this.authService.updateCustomerLoginEmails([]);
        }
      })
    );
  }

  /**
   * Register to the application using a O365 account with O365 s SSO
   * @param o365User - Object containing O365 account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithOffice365(o365User: M365LoginParams): Observable<ResponseO365SSO> {
    o365User.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseO365SSO>('/account/authenticate/registerwithoffice365', o365User)
      .pipe(this.operator.extractResponseO365SSO());
  }

  /**
   * Register to the application using a O365 account with O365 s SSO trough Existing domain scenario
   * @param o365User - Object containing O365 account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithOffice365ExistingDomain(reqBody: M365ExistingDomainBody): Observable<ResponseO365SSO> {
    reqBody.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseO365SSO>('/account/authenticate/registerwithoffice365ExistingDomain', reqBody)
      .pipe(this.operator.extractResponseO365SSO());
  }

  /**
   * Register to the application using a Google account with googleSSO trough Existing domain scenario
   * @param googleUser - Object containing Google account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithGoogleExistingDomain(reqBody: GoogleExistingDomainBody): Observable<ResponseGoogleLogin> {
    reqBody.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseGoogleLogin>('/account/authenticate/registerwithgoogleExistingDomain', reqBody)
      .pipe(this.operator.extractResponseGoogleLogin());
  }

  /**
   * Creates a new mailtastic account
   * @param newAccount - Object containing information used to create a new account
   * @returns Observable containing success of operation
   */
  createNewUser(newAccount: UserSignUp): Observable<IResponseMessageCode> {
    newAccount.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<IResponseMessageCode>('/account/createuser', newAccount, { params: { isFromAngularTs: true } })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Enables multiple signatures
   * @returns
   */
  enableMultiSignature(): unknown {
    return this.http.put('/users/enablemultisignature', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Opt-out from mailtastic application
   * @param params - Object contains information about user email address and unique code
   * @returns
   */
  optOut(params: IUserOptOutDetails): Observable<true> {
    // TODO add authorization: false
    return this.http.post<IResponse>('/account/optout', params).pipe(this.operator.extractResponse());
  }

  /**
   * Opt-out from mailtastic application
   * @param params -
   * @returns
   */
  undoOptOutLink(params: unknown): unknown {
    // TODO add authorization: false
    return this.http.post('/account/undooptoutlink', params).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Opt-out from mailtastic application
   * @param id -
   * @returns
   */
  undoOptOut(id: string): Observable<IResponse> {
    // TODO add authorization: false
    return this.http.post<IResponse>('/account/undooptout', { id: id }).pipe(this.operator.extractResponseOrMessage());
  }

  /**
   * Resends the registration confirmation email
   * @param email - Account email to send registration confirmation
   * @returns Observable containing success or code
   */
  resendRegistrationConfirmationEmail(email: string): Observable<ResponseMessageOrCode> {
    return this.http
      .post<ResponseMessageOrCode>('/account/authenticate/resendregistrationconfirmation', { email })
      .pipe(this.operator.extractResponseSuccessOrMessage());
  }

  /**
   * Gets the HTML snippet
   * @returns
   */
  getHtmlSnippet(): unknown {
    return this.http.post('/employees/snippet', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the overall statistics for the account
   * @returns
   */
  getOverallStats(): Observable<GetOverallStatsResponse[]> {
    return this.http
      .get<IResponseData<GetOverallStatsResponse[]>>('/users/stats/overall')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * User wants to have a custom domain from which the campaign and images have to be loaded to avoid spam scoring
   * @param domain -
   * @returns
   */
  sendCustomDomainRequest(domain: unknown): unknown {
    return this.http.post('/users/customdomain/request', { domain }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the amount of users
   * @returns
   */
  getAmountOfUsers(): unknown {
    return this.http.get('/users/stats/overall').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks the token
   * @param params -
   * @returns
   */
  checkToken(params: unknown): unknown {
    // TODO add authorization: false
    return this.http.post('/account/authenticate/checktoken', params).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Create a beta user
   * @param userobject -
   * @returns
   */
  createBetaUser(userobject: IUser): unknown {
    return this.http.post('/users/createuser/beta', userobject).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Activate a mailtastic account
   * @param activationCode - Activation code
   * @param employeeId - Unique employee ID
   */
  activateAccount(activationCode: string, employeeId: string): Observable<IUserCheckInvitationCode> {
    return this.http
      .post<IResponseData<IUserCheckInvitationCode>>('/account/activate/admin', { activationCode, employeeId })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Activates the new external admin account
   * @param params -
   * @returns
   */
  activateExternalAccount(activationCode: string, adminId: string): Observable<ActivateExternalAdminResponse> {
    return this.http
      .post<DataOrSuccess<ActivateExternalAdminResponse>>('/account/activate/admin/external', {
        activationCode,
        adminId
      })
      .pipe(this.operator.extractResponseDataOrSuccess());
  }

  /**
   * Check the activation status of admin
   * @param activationCode - Activation code
   * @param adminId - Admin id
   * @returns - Observable of activation status
   */
  checkExternalAccount(activationCode: string, adminId: string): Observable<boolean> {
    return this.http
      .post<DataOrSuccess<boolean>>('/account/activate/admin/check', {
        activationCode,
        adminId
      })
      .pipe(this.operator.extractResponseDataOrSuccess());
  }

  /**
   * Get assigned roles of given admin & given account
   * @param adminId - Admin id
   * @param accountId - Account id
   * @returns Observable of roles
   */
  getRolesByAdminIdAndAccountId(adminId: string, accountId: string): Observable<RBARole[]> {
    return this.http
      .get<DataOrMessage<RBARole[]>>(`/account/rolesByAdminAndAccount/${adminId}/${accountId}`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Gets all proven Expert Accounts
   * @returns
   */
  getProvenExpertAccounts(): unknown {
    return this.http.get('/users/provenexpert').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Deletes one proven expert account
   * @param id -
   * @returns
   */
  deleteProvenExpertAccount(id: string): unknown {
    return this.http.delete('/users/delete/peaccount/' + id).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Deletes many proven expert accounts via the list of ids
   * @param ids -
   * @returns
   */
  deleteManyProvenExpertAccounts(ids: string[]): unknown {
    return this.http.post('/users/del/many/peaccounts', { pe_ids: ids }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the proven expert account via the id
   * @param accountID -
   * @returns
   */
  getProvenExpertAccountByID(accountID: string): unknown {
    return this.http.put('/users/provenexpert/getAccount', { accountID }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Creates a proven expert widget
   * @param accountID -
   * @param data -
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  createWidget(accountID: string, data: Object): unknown {
    return this.http
      .post('/users/provenexpert/createwidget', { accountID, data })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Creates a new proven expert account
   * @param accountID -
   * @returns
   */
  createProvenExpertAccount(accountID: string): unknown {
    return this.http.post('/users/provenexpert', { accountID }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the EasySync settings
   * @returns The easysync settings
   */
  getEasySyncSettings(): Observable<ResponseEasySync> {
    return this.http
      .get<IResponseData<ResponseEasySync>>('/users/easysyncsettings')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Changes an EasySync setting
   * @param setting - EasySync setting to change
   * @param value - New setting value
   * @returns Updated easysync settings
   */
  changeEasySyncSettings(
    setting: 'disableEasySync' | 'disableUpdate',
    value: boolean
  ): Observable<ChangeEasySyncSettings> {
    return this.http
      .put<IResponseData<ChangeEasySyncSettings>>('/users/easysyncsettings', { setting, value })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the account data
   * @remarks
   * Here there is no response code or status in return type `UserGetAccountData`
   * @returns Observable of get user account data
   */
  getAccountData(): Observable<UserGetAccountData> {
    return this.http.get<UserGetAccountData>('/users/accountdata').pipe(
      map(result => {
        if (!result) throw new Error(this.alert.translateDataNotLoaded());
        return result;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Sets the email notification settings
   * @param settings - The object of email notification settings
   * @returns Observable of changed email notification settings
   */
  setEmailNotificationSetting(settings: INotificationSetting): Observable<boolean> {
    return this.http
      .put<IResponseMessageCode>('/users/accountdata/emailnotifysetting', settings)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Sets the email notification language
   * @param settings - The object of language settings
   * @returns Observable of changed email notification language
   */
  setEmailNotificationLanguage(settings: INotificationLanguage): Observable<boolean> {
    return this.http
      .put<IResponseMessageCode>('/users/accountdata/emailnotifylanguage', settings)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Sets custom alter text for campaign
   * @param altText -
   * @returns
   */
  setAltText(altText: unknown): Observable<boolean> {
    return this.http.put<IResponse>('/users/accountdata/altText', { altText }).pipe(this.operator.extractResponse());
  }

  /**
   * Logs out of the app
   */
  logout(): void {
    // Remove storage factory
    this.authService.removeToken();
    void this.router.navigate(['login']);
  }

  /**
   * Updates the account data
   * First name and Last name, Company name, etc
   * @param data - New account data to update
   * @returns Observable containing success of operation
   */
  setAccountData(data: Partial<IUpdateUser>): Observable<IResponseMessageCode> {
    return this.http.post<IResponseMessageCode>('/users/accountdata', data).pipe(
      this.operator.extractResponseMessageCode(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
      })
    );
  }

  /**
   * Sends the switch settings email
   * @param data - Account data
   * @param language - Language of the app
   * @param admins - Admins with assigned roles
   * @returns Observable containing success of operation
   */
  sendSwitchUserRolesSettingsMail(
    data: Partial<IUpdateUser>,
    language: string,
    admins: UserGetAdvancedAdmins[]
  ): Observable<IResponseMessageCode> {
    return this.http.post<IResponseMessageCode>('/users/sendSwitchUserRolesMail', { ...data, language, admins }).pipe(
      this.operator.extractResponseMessageCode(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
      })
    );
  }

  /**
   * Gets the employees with hidden profile photo
   * @returns Observable of employees
   */
  employeeWithHiddedPhoto(): Observable<EmployeesWithHiddenProfilePhoto[]> {
    return this.http
      .get<DataOrMessage<EmployeesWithHiddenProfilePhoto[]>>(`/v2/employees/withhiddenpicture`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Gets the employees with hidden fields
   * @returns Observable of employees
   */
  employeeWithHiddenFields(): Observable<EmployeesWithHiddenFields[]> {
    return this.http
      .get<DataOrMessage<EmployeesWithHiddenFields[]>>(`/v2/employees/withhiddenfields`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Updates the EasySync mac settings for new users
   * @param data - Outlook and apple mail settings
   * @returns Observable containing success of operation
   */
  setEasySyncNewUsers(data: { outlookMacSyncNewUsers: boolean; appleMailSyncNewUsers: boolean }): Observable<true> {
    return this.http.put<IResponse>('/users/accountdata/easysync', data).pipe(this.operator.extractResponse());
  }

  /**
   * Sets the company info
   * @param data -
   * @returns
   */
  setCompanyInfo(data: unknown): unknown {
    return this.http.post('/users/companyinfo', data).pipe(
      this.operator.extractUnknownResponse(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
      })
    );
  }

  /**
   * Resets the lost password
   * @param email - Email to reset the password for
   * @returns Observable containing success of operation
   */
  resetPassword(email: string): Observable<IResponseMessageCode> {
    return this.http
      .post<IResponseMessageCode>('/account/resetpassword', { email })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the new password
   * @param data -
   * @returns
   */
  setNewPassword(data: unknown): Observable<IResponseMessageCode> {
    // TODO add authorization: false
    return this.http
      .post<IResponseMessageCode>('/account/setnewpass', data)
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Adds payment credentials
   * @param data -
   * @returns
   */
  addPaymentCredentials(data: AddPaymentCredentials): Observable<boolean> {
    return this.http
      .post<IResponseData<boolean>>('/users/paymentcreds', data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the company dataset data which is used for signatures
   * @param datasetId - ID of the company dataset
   * @param tagObject - Object containing new info to update with
   * @returns Observable containing the saved tag object
   */
  setCompanyInfoSingle(datasetId: string, tagObject: TSignaturePlaceholder): Observable<TSignaturePlaceholderValue> {
    return this.http.post<IUserSetCompanyInfoSingle>('/employees/companyinfo/single', { datasetId, tagObject }).pipe(
      map(result => {
        if (!result.success || !result?.savedObject) throw new Error(this.alert.translateDataNotLoaded());
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
        return result.savedObject;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Remove Company Logo Image from company default dataset
   * @param datasetId - ID of the company dataset
   * @returns Observable of delete company logo
   */
  deleteCompanyLogo(datasetId: string): Observable<true> {
    return this.http.get<IResponse>(`/employees/deleteCompanyLogo/${datasetId}`).pipe(this.operator.extractResponse());
  }

  /**
   * Gets signature campaign data based on added recipient list
   * @param senderEmail - The selected employee email address
   * @param recipients - The recipients list
   * @returns The campaign details of recipients with target group details
   */
  getRecipientCampaignPreview(senderEmail: string, recipients: string[]): Observable<RecipientCampaignPreviewResponse> {
    return this.http
      .post<DataOrMessage<RecipientCampaignPreviewResponse>>('/v2/signatures/preview/recipients-campaign', {
        senderEmail,
        recipients
      })
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Verifies the password
   * @param password -
   * @returns
   */
  verifyPassword(password: string): unknown {
    return this.http.get('/users/verifyPwd/' + password).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Marks initial introduction tour as seen
   * @returns
   */
  setTourWasSeen(): unknown {
    return this.http.post('/users/marktourasseen', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets track recipient flags
   * @returns
   */
  getRecipientTracking(): Observable<IAccount> {
    return this.http.get<IResponseData<IAccount>>('/users/recipientTracking').pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the ignored internal domains
   * @returns
   */
  getIgnoreInternalDomains(): Observable<IResponseMessageCode> {
    return this.http
      .get<IResponseMessageCode>('/users/ignoreInternalDomains/get')
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the ignored internal domains
   * @param val -
   * @returns
   */
  setIgnoreInternalDomains(val: unknown): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/ignoreInternalDomains/set', { ignoreInternalDomains: val })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Gets the record for the foreign domains
   * @returns
   */
  getRecordForeignDomains(): unknown {
    return this.http.get('/users/recordForeignDomains/get').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the flag for tracking the foreign domains
   * @param val -
   * @param showLeads -
   * @returns
   */
  setRecordForeignDomains(val: boolean, showLeads: boolean): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/recordForeignDomains/set', {
        recordForeignDomains: val,
        showLeads: showLeads
      })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the flag for tracking the foreign contacts
   * @param val -
   * @returns
   */
  setRecordForeignContacts(val: boolean): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/users/recordForeignContacts', { recordForeignContacts: val })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Sets the flag for tracking accounts
   * @param val -
   * @returns
   */
  setAppendRecContent(val: boolean): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/users/appendRecContent', { appendRecContent: val })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Sends the invitation
   * @param val -
   * @returns
   */
  setCampaignTracking(val: boolean): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/disableCampaignTracking', { disableCampaignTracking: val })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the flag for tracking contacts
   * @param val -
   * @returns
   */
  setAppendFullAddress(val: boolean): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/appendFullAddress', { appendFullAddress: val })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Gets the new seen leads
   * @returns
   */
  getNewLeadsSeen(): unknown {
    return this.http.get('/users/seenNewLeads/get').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the new seen leads
   * @returns
   */
  setNewLeadsSeen(): unknown {
    return this.http.put('/users/seenNewLeads/set', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the list of admins for this account
   * @returns
   */
  getAdmins(): Observable<IUserGetAdmins[]> {
    return this.http
      .get<IResponseData<IUserGetAdmins[]>>('/users/administrators')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the list of admins for this account with advanced settings
   * @returns Observable of advanced admins
   */
  getAdminsWithRBASettings(): Observable<UserGetAdvancedAdmins[]> {
    return this.http
      .get<ResponseData<UserGetAdvancedAdmins[]>>('/users/administrators/advanced')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * @Remarks Operator.extractResponseMessage() not used because we need to display different messages based on backend api response.
   * Sends the advanced admin invitation
   * @param email - Email of advanced admin
   * @param roles - Roles to be assigned
   * @param language - Language of the app
   * @returns - Invitation status
   */
  sendAdvancedAdminInvitation(email: string, roles: RBARole[], language: string): Observable<ResponseMessage> {
    return this.http
      .post<ResponseMessage>('/users/administrators/sendAdvancedAdminInvitation', {
        email,
        roles,
        language
      })
      .pipe(
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Resend invitation to the advanced admin
   * @param id - Admin id
   * @param email - Admin email
   * @param roles - Assigned roles
   * @param language - Language of the app
   * @returns - Invitation status
   */
  resendAdvancedAdminInvitation(
    id: string,
    email: string,
    roles: RBARole[],
    language: string
  ): Observable<ResponseMessageOrCode> {
    return this.http
      .post<ResponseMessageOrCode>('/users/administrators/resendAdvancedAdminInvitation', {
        adminId: id,
        adminEmail: email,
        roles,
        language
      })
      .pipe(this.operator.extractResponseSuccessOrMessage());
  }

  /**
   * Method is used to manage assigned roles
   * @param email - Email of advanced admin
   * @param roles - Roles to be manage
   * @param adminType - Admin type
   * @param language - Language of the app
   * @returns - Observable of success
   */
  manageAdvancedAdminRoles(email: string, roles: RBARole[], adminType: string, language: string): Observable<true> {
    return this.http
      .post<Response>('/users/administrators/manageAdvancedAdminRoles', {
        email,
        roles,
        adminType,
        language
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Method is used to manage assigned roles
   * @param emails - Emails of advanced admin
   * @returns - Observable of success
   */
  assignDefaultRoles(emails: string[]): Observable<true> {
    return this.http
      .post<Response>('/users/administrators/assignDefaultRoles', {
        emails
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Gets the current admin data
   * @returns
   */
  getAdmin(): Observable<IUserGetAdmin> {
    return this.http
      .get<IResponseData<IUserGetAdmin>>('/users/administrator')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Deletes the admin
   * @param adminId -
   * @returns
   */
  deleteAdmin(adminId: string): Observable<IDataset[]> {
    return this.http
      .delete<IResponseData<IDataset[]>>('/users/administrators/' + adminId)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Deletes external admin
   * @param adminId -
   * @returns
   */
  deleteExternalAdmin(adminId: string) {
    return this.http.delete('/users/administrators/external/' + adminId).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks if mail has already expired.
   * @param id -
   * @returns
   */
  checkExpiredMail(id: string) {
    // TODO add authorization: false
    return this.http.get('/account/checkexpiredmail/' + id).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sends the invitation
   * @param email -
   * @returns
   */
  sendInvitation(email: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>('/users/administrators/sendInvitation', { email, isFromNewAngularTs: true })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * @Remarks Operator.extractResponseMessage() not used because we need to display different messages based on backend api response.
   * Sends the external admin invitation
   * @param email - Email of external admin
   * @returns - Invitation status
   */
  sendExternalAdminInvitation(email: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>('/users/administrators/sendExternalAdminInvitation', { email, isFromNewAngularTs: true })
      .pipe(
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Checks the invitation code
   * @param ac - The invitation code to check
   * @returns Observable containing the succes of operation
   */
  checkInvitationCode(ac: string): Observable<IUserCheckInvitationCode> {
    return this.http.post<IUserCheckInvitationCode>('/account/check/invitation/code', { ac }).pipe(
      map(value => {
        if (value.code === 4) {
          throw new Error('INVITATION_LINK_EXPIRE');
        } else if (!value.success) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        return value;
      }),
      catchError(e => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.message === 'INVITATION_LINK_EXPIRE') {
          void this.alert.defaultErrorMessage(this.alert.translate('INVITATION_LINK_EXPIRE'));
        } else {
          void this.alert.defaultErrorMessage(this.alert.translateTechnicalError());
        }
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Adds a new admin to an existing account
   * @param newUser - Object containing new admin information
   * @returns Observable containing success of operation and login information
   */
  updateNewAdminByInvitation(
    newUser: IUserInvitationSignUp
  ): Observable<IUserUpdateNewAdminByInvitation | IUserLoginFailed> {
    return this.http
      .post<IUserUpdateNewAdminByInvitation | IUserLoginFailed>('/account/update/invitation', newUser)
      .pipe(
        map(value => {
          if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
          return value;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Creates a new admin
   * @param gender -
   * @param firstname -
   * @param lastname -
   * @param email -
   * @param password -
   * @returns
   */
  createNewAdmin(gender: string, firstname: string, lastname: string, email: string, password: string) {
    return this.http
      .post('/users/administrators', { gender, firstname, lastname, email, password })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Changes the admin password
   * @param oldpass -
   * @param newpass -
   * @param adminId -
   * @returns
   */
  changeAdminPassword(oldpass: string, newpass: string, adminId: string): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/users/administrators/password', { oldpass, newpass, adminId })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Edits the admin data
   * @param firstname -
   * @param lastname -
   * @param gender -
   * @param email -
   * @param adminId -
   * @returns
   */
  changeAdminData(firstname: string, lastname: string, gender: string, email: string, adminId: number) {
    return this.http
      .put('/users/administrators/contactinfo', { firstname, lastname, gender, email, adminId })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Generates an API secret
   * @param id -
   * @param email -
   * @returns
   */
  resendExternalAdminInvitationEmail(id: string, email: string): Observable<ResponseMessageOrCode> {
    return this.http
      .post<ResponseMessageOrCode>('/users/administrators/resendExternalAdminInvitation', {
        adminId: id,
        adminEmail: email,
        isFromNewAngularTs: true
      })
      .pipe(this.operator.extractResponseSuccessOrMessage());
  }

  /**
   * Generates an API secret
   * @param id -
   * @param email -
   * @returns
   */
  resendAdminInvitation(id: string, email: string): Observable<ResponseMessageOrCode> {
    return this.http
      .post<ResponseMessageOrCode>('/users/administrators/resendAdminInvitation', {
        recipientAdminId: id,
        adminEmail: email,
        isFromNewAngularTs: true
      })
      .pipe(this.operator.extractResponseSuccessOrMessage());
  }

  /**
   * Generates an API secret
   * @param secret -
   * @returns
   */
  generateApiSecret(secret: string): Observable<true> {
    return this.http
      .post<IResponse>('/users/publicapi/generatesecret', { secret: secret })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Update use info when logged in first time
   * @param info - Object will have user info about user role
   * @param amountOfEmployees - Number of employee
   * @param firstname - Users firstname
   * @param lastname - Users lastname
   * @param companyName - Users company name
   * @param firstStepAfterSchedule - The signup tour first step after scheduling a tour
   * @param firstStep - The signup tour first step
   * @returns - Observable containing success flag of data updated in database
   */
  updateUserInfo(
    info: UserLoggedInfo,
    amountOfEmployees?: number,
    firstname?: string,
    lastname?: string,
    companyName?: string,
    firstStepAfterSchedule?: string,
    firstStep?: string
  ): Observable<true> {
    info.utm = this.sourcebusterCookieService.parseSourcebusterCookies();
    const data = {
      info: info,
      firstname: firstname || '',
      lastname: lastname || '',
      companyName: companyName || '',
      amountOfEmployees: amountOfEmployees || 0,
      accountId: this.authService.decodeToken().accountId,
      adminId: this.authService.decodeToken().adminId
    };
    return this.http.post<IResponse>('/users/updateUserInfo', data).pipe(
      this.operator.extractResponse(),
      tap(() => {
        const intercomAttributes = {
          signup_directory: info.whereDoYourEmployeeDataLive?.answer || 'Others',
          signup_crm: this.getCRMNames(info),
          signup_firstname: data.firstname,
          signup_lastname: data.lastname,
          signup_jobtitle: info.jobTitle || '',
          signup_phone: info.phoneNumber || '',
          signup_companywebsite: info.companyWebsite || '',
          signup_demorequest: info.requestedOnboarding || false,
          signup_firststep: this.getFirstStep(firstStepAfterSchedule || firstStep)
        };
        this.intercomService.updateInfo(intercomAttributes);
      })
    );
  }

  /**
   * Update use info when logged in first time
   * @param info - Object will have user info about user role
   * @param amountOfEmployees - Number of employee
   * @param firstname - Users firstname
   * @param lastname - Users lastname
   * @param companyName - Users company name
   * @returns - Observable containing success flag of data updated in database
   */
  updateUserInfoV2(
    info: UserLoggedInfoV2,
    amountOfEmployees?: number,
    firstname?: string,
    lastname?: string,
    companyName?: string
  ): Observable<true> {
    info.utm = this.sourcebusterCookieService.parseSourcebusterCookies();
    info.whatIsYourRole = info.isCompanyAdmin ? 'company-admin' : 'non-admin';

    const data = {
      info: info,
      firstname: firstname || '',
      lastname: lastname || '',
      companyName: companyName || '',
      amountOfEmployees: amountOfEmployees || 0,
      accountId: this.authService.decodeToken().accountId,
      adminId: this.authService.decodeToken().adminId
    };
    return this.http.post<IResponse>('/users/updateUserInfo', data).pipe(
      this.operator.extractResponse(),
      tap(() => {
        const intercomAttributes = {
          signup_firstname: data.firstname,
          signup_lastname: data.lastname,
          signup_jobtitle: info.jobTitle || '',
          signup_phone: info.phoneNumber || '',
          signup_companywebsite: info.companyWebsite || '',
          status: 'trial'
        };
        this.intercomService.updateInfo(intercomAttributes);
      })
    );
  }

  /**
   * Gets the first step name of signup tour after completion of all
   * @param translationTag - The translation tag
   * @returns - The first step name
   */
  private getFirstStep(translationTag = 'show_dashboard'): string {
    const tagToStepMapping = {
      signup_firststeps_4: 'show_dashboard',
      signup_firststeps_9: 'show_dashboard',
      ignup_firststeps_10: 'show_dashboard',
      signup_firststeps_3: 'personalize_signature',
      signup_tour_step_create_first_campaign: 'create_campaign',
      signup_firststeps_5: 'install_signature',
      signup_firststeps_6: 'import_m365',
      signup_firststeps_6_B: 'import_google'
    };

    const signup_firststep = tagToStepMapping[translationTag as keyof typeof tagToStepMapping] || '';

    return signup_firststep;
  }

  /**
   * Gets the CRM places
   * @param info - Object will have user info about user role
   * @returns - The string of CRM places
   */
  private getCRMNames(info: UserLoggedInfo): string {
    let whichCrmDoYouHaveInPlaceAns = '';
    whichCrmDoYouHaveInPlaceAns =
      info.whichCrmDoYouHaveInPlace && info.whichCrmDoYouHaveInPlace?.answers.length > 0
        ? info.whichCrmDoYouHaveInPlace?.answers.toString()
        : '';

    if (info.whichCrmDoYouHaveInPlace?.other) {
      whichCrmDoYouHaveInPlaceAns = `${whichCrmDoYouHaveInPlaceAns} 'Others'`;
    }
    return whichCrmDoYouHaveInPlaceAns;
  }

  /**
   * Sets the ignored internal domains
   * @memberof mailtasticApp.services.userService
   * @param val - anonymous tracking is active or not into the account
   * @returns -  observable containing success flag of data updated in database
   */
  setAnonymousTracking(val?: boolean): Observable<true> {
    return this.http
      .put<IResponse>('/users/anonymousTracking/set', {
        anonymousTracking: val
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Returns the account's outlook addin settings
   * @returns Account's outlook addin settings
   */
  getOutlookAddinSettings(): Observable<UserGetOutlookAddinSettings> {
    return this.http
      .get<ResponseData<UserGetOutlookAddinSettings>>('/users/outlookaddinsettings')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates an Outlook Addin setting with a new value
   * @param setting - Setting to update
   * @param value - New value to set
   * @returns Nr. of enabled clients and last update date
   */
  changeOutlookAddinSettings(
    setting: keyof UserGetOutlookAddinSettings,
    value: boolean
  ): Observable<UserChangeOutlookAddinSettings> {
    return this.http
      .put<ResponseData<UserChangeOutlookAddinSettings>>('/users/outlookaddinsettings', { setting, value })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get the user logged in info of signup tour
   * @returns User logged in info of signup tour
   */
  getUserLoggedInInfo(): Observable<OnboardingAnswerDataV2> {
    return this.http
      .get<DataOrMessage<OnboardingAnswerDataV2>>('/v2/accountInfo/signuptourinfo')
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Updates the user logged in info of signup tour
   * @param data - The loggedIn user data
   * @param authData - The auth data
   * @returns The response of success
   */
  updateUserLoggedInInfo(data: UserLoggedInfoV2, authData: IMAILTASTIC_AUTHORIZATION_SCOPE): Observable<true> {
    const dataToSave = {
      ...data,
      whereDoYourEmployeeDataLive: this.translateService.translate(data.whereDoYourEmployeeDataLive || ''),
      whichCrmDoYouHaveInPlace: this.translateService.translate(data.whichCrmDoYouHaveInPlace || ''),
      howDoYouWantToDoFirst: this.translateService.translate(data.howDoYouWantToDoFirst || '')
    };
    return this.http
      .put<DataOrMessage<true>>('/v2/accountInfo/signuptourinfo', { data: JSON.stringify(dataToSave) })
      .pipe(
        this.operator.extractResponseDataOrMessage(),
        tap(() => {
          const today = new Date(authData.createdAt);
          const trialEndDate = new Date(authData.createdAt);
          trialEndDate.setDate(today.getDate() + 15);

          const intercomAttributes = {
            plan: this.getPlanName(authData),
            signup_crm: dataToSave.whichCrmDoYouHaveInPlace,
            signup_directory: dataToSave.whereDoYourEmployeeDataLive,
            signup_firststep: dataToSave.howDoYouWantToDoFirst,
            tour_finished: true,
            trial_over_date: format(trialEndDate, 'dd-MM-yyyy')
          };

          this.intercomService.updateInfo(intercomAttributes);
        })
      );
  }

  /**
   * Updates the user logged in info of signup tour
   * @param authData - The auth data
   * @returns The subscription plan name
   */
  private getPlanName(authData: IMAILTASTIC_AUTHORIZATION_SCOPE): string {
    if (authData.isUserOnBasicPlan) {
      return 'Professional (Basic)';
    } else if (authData.isUserOnAdvancedPlan) {
      return 'Marketing (Advanced)';
    } else if (authData.isUserOnTargetPlan) {
      return 'Marketing Pro (Target)';
    } else {
      return 'unknown';
    }
  }

  /**
   * Send an email to non MT admin to create integration connection
   * @param data - The data to send an email to non MT admin
   * @returns Observable of success
   */
  sendEmailToWorkspaceAdmin(data: SendMailDataToNonMTAdmin): Observable<boolean> {
    return this.http
      .post<DataOrMessage<boolean>>(`/v2/accountInfo/send-connection-mail/non-mt-admin`, data)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Send an email to MT admin to inform that non MT admin has created an integration connection
   * @param emailText - The email content
   * @param language - Language of the app
   * @returns Observable of success
   */
  sendSuccessEmailToMTAdmin(emailText: string, language: string): Observable<true> {
    return this.http
      .post<DataOrMessage<true>>(`/v2/accountInfo/send-connection-success-mail/mt-admin`, {
        emailText,
        language
      })
      .pipe(this.operator.extractResponseDataOrMessage());
  }
}
