import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserGetAccountData } from '@services/user/user-service.interface';

// Ref: Install Hotjar in MT-1756 ticket
// Ref: Remove Hotjar in MT-2612 ticket
import Hotjar from '@hotjar/browser';

@Injectable({
  providedIn: 'root'
})
export class HotjarCookieService {
  /**
   * Initialize hotjar
   * @param accountData - Object of account data
   */
  init(accountData: UserGetAccountData): void {
    try {
      const siteId = Number(environment.hotJarAppId);
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
      Hotjar.identify(accountData.id, {
        email: accountData.email
      });
    } catch (err) {}
  }

  /**
   * Clear hotjar related cookies
   */
  clearHotjarCookies(): void {
    localStorage.removeItem('hjActiveViewportIds');
    sessionStorage.removeItem('hjViewportId');
    sessionStorage.removeItem('_hjRecordingEnabled');
    sessionStorage.removeItem('_hjRecordingLastActivity');

    // Need to check of hotjar
    const w: any = window;
    w.hj?.eventStream?._ws?.close();
  }
}
